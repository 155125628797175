import { mapActions } from 'vuex'
import { myMixin } from '../../../../mixins';

export default{
  mixins: [myMixin],
  data: () => ({
    collections: [],
    params: {},
    message: '',
    error: false,
    errors: {},
    loading: false,
    meta: {},
    itemDelete: {},
    model: {},
    show: false,
    showForm: false,
    text: 'Carregando dados...',
    loadingProgress: false,
    textDelete: '',
    deleting: false,
    
    headers: [
        { text: 'Código', value: 'code'},
        { text: 'Nome', value: 'name'},
        { text: 'Departamento', value: 'departament.name'},
        { text: 'Compõe CH', value: 'is_work_load', type:'boolean'},
        { text: 'Status', value: 'active', type:'boolean'},
        { text: 'Ações', value: 'actions'},
    ]
    }),
    
    watch: {
      params(params_new, params_old){
        if(params_new != params_old) {
            this.getData()
        }
      },
       //Função para tratar quando um item for exibido para deletar
      itemDelete(item_new, item_old){
          if(item_new !== item_old && Object.keys(item_new).length > 0)
          {
              this.show = true;
              this.textDelete = "Deseja excluir o item " + item_new.name + "?";
          } else {
              this.show = false
              this.textDelete = '';
          }
      }, //Função para limpar a propriedade do usuário que será excluído quando usuário clica no cancelar
      show(show_new, show_old) {
          
          if(show_new != show_old && !show_new) {
              this.itemDelete = {};
          }
      },

      model(item_new, item_old){
          
        if(item_new !== item_old && this.model.id)
          {
              this.showForm = true;
              this.errors = {};
          } else if(Object.keys(this.model).length === 0){
              this.showForm = false;
              this.errors = {};
          }           
      },

      showForm(show_new, show_old) {
          if(show_new != show_old && !show_new) {
              this.model = {};
          }
      },
    },
    components: {
      
    },
    mounted(){
       
    },
    created() {
      
    },
    methods: {
      ...mapActions('activity', ['ActionIndexActivityCategories', 'ActionDeleteActivityCategory']),
        
      getData(){
      
        this.error = false;
        this.message = '';
        this.loadingProgress = true;
        this.showForm = false;

        this.params.with = 'departament';
        
        this.ActionIndexActivityCategories(this.params)
            .then((res) => {
                this.collections = res.data;
                this.meta = res.meta;
            })
            .finally(() => {
                this.loading = false;
                this.loadingProgress = false;
            })
    },

    deleteItem()
        {
            this.deleting = true;
            this.message = '';

            this.ActionDeleteActivityCategory({id: this.itemDelete.id})
                .then((res) => {
                    this.message = res.message;
                    this.show = false;
                    this.getData();
                })
                .catch((error) =>{
                  this.message = error.message;
                  this.error = true;
                })
                .finally(() => {
                    this.deleting = false;
                });
        }

  },

}
